<template>
    <b-row>
        <b-col sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.area_under_tea')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Year" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="year">
                                                                <template v-slot:label>
                                                                    {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select plain v-model="tab_main.year"
                                                                    :options="yearList"
                                                                    :disabled="isGardenAdminCheckGardenId() ? true : false"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Return Type" vid="return_type" rules="required|min_value:1" v-slot="{ errors }">
                                                                <b-form-group label-for="return_type">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.return_type') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-radio-group v-model="tab_main.return_type"
                                                                        :options="returnTypeList"
                                                                        :disabled="isGardenAdminCheckGardenId() ? true : false"
                                                                        @change="resetGarden()"
                                                                        value-field="value" text-field="text"
                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                    </b-form-radio-group>
                                                                    <div class="invalid-feedback d-block">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="tab_main.return_type && tab_main.return_type === 1">
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Garden Factory Id" vid="garden_fact_id" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="garden_fact_id">
                                                                <template v-slot:label>
                                                                {{ $t('teaGarden.tea_garden') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="tab_main.garden_fact_id"
                                                                    :disabled="isGardenAdminCheckGardenId() ? true : false"
                                                                    :options="masterTeaGardenGenInfoList"
                                                                    @change="getGfAutoId(tab_main.garden_fact_id, 'garden')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="tab_main.garden_fact_id" sm="6">
                                                            <ValidationProvider name="Garden ID" vid="garden_id" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="garden_id">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.tea_garden_id') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="tab_main.return_type && tab_main.return_type === 2">
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Garden Factory" vid="garden_fact_id" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="garden_fact_id">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.bought_leaf_factory') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="tab_main.garden_fact_id"
                                                                    :options="masterBoughtLeafFactoryList"
                                                                    :disabled="isGardenAdminCheckGardenId() ? true : false"
                                                                    @change="getGfAutoId(tab_main.garden_fact_id, 'factory')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="tab_main.garden_fact_id" sm="6">
                                                            <ValidationProvider name="Factory ID" vid="factory_id" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="factory_id">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bought_auto_id') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="!isReady">
                                                        <b-col sm="12" class="py-3">
                                                            <h6 v-if="$i18n.locale == 'en'">Searching Previous Year Data!<br>Please wait...</h6>
                                                            <h6 v-else>আগের বছরের ডেটা অনুসন্ধান করা হচ্ছে!<br>অনুগ্রহপূর্বক অপেক্ষা করুন...</h6>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group v-if="isReady" label-cols-lg="3" :label="($i18n.locale == 'en' ? '1a) ' : '১ক) ') + $t('teaGarden.area_under_seeds_and_nurseries')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.seed_bari', 'en')" vid="seed_bari" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="seed_bari">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.seed_bari') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.seed_bari" @input="subTotalA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.seed_nurseries', 'en')" vid="seed_nurse" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="seed_nurse">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.seed_nurseries') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.seed_nurse" @input="subTotalA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.clone_nurseries', 'en')" vid="clone_nurse" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="clone_nurse">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.clone_nurseries') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.clone_nurse" @input="subTotalA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_a" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_a">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.sub_total_a" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group v-if="isReady" label-cols-lg="3" :label="($i18n.locale == 'en' ? '1b) ' : '১খ) ') + $t('teaGarden.area_under_tea_cultivation')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.immature_tea', 'en')" vid="immature_tea" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="immature_tea">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.immature_tea') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.immature_tea" @input="subTotalAA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.bushes_six_ten', 'en')" vid="bushes_six_ten" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="bushes_six_ten">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bushes_six_ten') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.bushes_six_ten" @input="subTotalAA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.bushes_eleven_forty', 'en')" vid="bushes_eleven_forty" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="bushes_eleven_forty">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bushes_eleven_forty') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.bushes_eleven_forty" @input="subTotalAA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.bushes_fortyone_sixty', 'en')" vid="bushes_fortyone_sixty" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="bushes_fortyone_sixty">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bushes_fortyone_sixty') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.bushes_fortyone_sixty" @input="subTotalAA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.bushes_above_sixty', 'en')" vid="bushes_above_sixty" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="bushes_above_sixty">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bushes_above_sixty') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.bushes_above_sixty" @input="subTotalAA" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_aa" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_aa">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_main.sub_total_aa" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneTabOneStoreApi } from '../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
    props: ['app_id', 'tab_main'],
    mixins: [teaGardenService],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            gf_auto_id: '',
            isReady: true
        }
    },
    created () {
        if (this.$route.query.id) {
            const typeName = this.tab_main.return_type === 1 ? 'garden' : 'factory'
            this.getGfAutoId(this.tab_main.garden_fact_id, typeName)
        } else {
            this.tab_main.year = new Date().getFullYear()
            if (this.isGardenAdminCheckGardenId()) {
                const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
                this.tab_main.return_type = gardenData.type_garden_factory_id
                this.tab_main.garden_fact_id = this.isGardenAdminCheckGardenId()
                const typeName = this.tab_main.return_type === 1 ? 'garden' : 'factory'
                this.getGfAutoId(this.tab_main.garden_fact_id, typeName)
            }
        }
    },
    // watch: {
    //     'tab_main.return_type': function (newVal, oldVal) {
    //         if (newVal !== oldVal) {
    //             this.tab_main.garden_fact_id = 0
    //             this.gf_auto_id = ''
    //         }
    //     }
    // },
    computed: {
        yearList () {
            return this.$store.state.TeaGardenService.commonObj.yearList.map(el => {
                return Object.assign({ value: el, text: this.$i18n.locale === 'en' ? el : this.$n(el, { useGrouping: false }) })
            })
        },
        masterTeaGardenGenInfoList () {
            return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
        },
        masterBoughtLeafFactoryList () {
            return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
        },
        returnTypeList () {
            return this.$store.state.TeaGardenService.commonObj.returnTypeList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        resetGarden () {
            this.tab_main.garden_fact_id = 0
            this.gf_auto_id = ''
        },
        customLoading (type) {
            if (type) {
                this.isReady = false
            } else {
                this.isReady = true
            }
        },
        subTotalA () {
            const seedBari = parseFloat(this.tab_main.seed_bari) ? parseFloat(this.tab_main.seed_bari) : 0
            const seedNurse = parseFloat(this.tab_main.seed_nurse) ? parseFloat(this.tab_main.seed_nurse) : 0
            const cloneNurse = parseFloat(this.tab_main.clone_nurse) ? parseFloat(this.tab_main.clone_nurse) : 0
            this.tab_main.sub_total_a = seedBari + seedNurse + cloneNurse
        },
        subTotalAA () {
            const item1 = parseFloat(this.tab_main.immature_tea) ? parseFloat(this.tab_main.immature_tea) : 0
            const item2 = parseFloat(this.tab_main.bushes_six_ten) ? parseFloat(this.tab_main.bushes_six_ten) : 0
            const item3 = parseFloat(this.tab_main.bushes_eleven_forty) ? parseFloat(this.tab_main.bushes_eleven_forty) : 0
            const item4 = parseFloat(this.tab_main.bushes_fortyone_sixty) ? parseFloat(this.tab_main.bushes_fortyone_sixty) : 0
            const item5 = parseFloat(this.tab_main.bushes_above_sixty) ? parseFloat(this.tab_main.bushes_above_sixty) : 0
            this.tab_main.sub_total_aa = item1 + item2 + item3 + item4 + item5
        },
        getGfAutoId (Id, typeName) {
            this.gf_auto_id = ''
            const List = typeName === 'garden' ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
            const Obj = List.find(el => el.value === parseInt(Id))
            if (typeof Obj !== 'undefined') {
                this.gf_auto_id = typeName === 'garden' ? Obj.garden_id_no : Obj.registration_no
            }
            // Emitted to get Previous year data (works only entry mode)
            if (!this.$route.query.id || this.isGardenAdminCheckGardenId()) {
                this.$emit('previous-year-data')
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.tab_main.app_id = this.app_id
                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnOneTabOneStoreApi, this.tab_main)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
