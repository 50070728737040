<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '2a) ' : '২ক) ') + $t('teaGarden.forest')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.rubber', 'en')" vid="rubber" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="rubber">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.rubber') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.rubber" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.bamboo', 'en')" vid="bamboo" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="bamboo">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bamboo') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.bamboo" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.planted_forest', 'en')" vid="planted_forest" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="planted_forest">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.planted_forest') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.planted_forest" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.natural_forest', 'en')" vid="natural_forest" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="natural_forest">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.natural_forest') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.natural_forest" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sungrass', 'en')" vid="sungrass" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="sungrass">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sungrass') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.sungrass" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.paddy_land', 'en')" vid="paddy_land" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="paddy_land">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.paddy_land') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.paddy_land" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.jackfruit', 'en')" vid="jackfruit" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="jackfruit">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.jackfruit') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.jackfruit" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.lemon', 'en')" vid="lemon" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="lemon">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.lemon') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.lemon" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.other_crop', 'en')" vid="other_crop" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="other_crop">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.other_crop') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.other_crop" @input="subTotalB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_b" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_b">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.sub_total_b" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '2b) ' : '২খ) ') + $t('teaGarden.other_non_agriculture')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.fallow_land', 'en')" vid="fallow_land" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="fallow_land">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.fallow_land') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.fallow_land" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.stream', 'en')" vid="stream" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="stream">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.stream') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.stream" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.masjid', 'en')" vid="masjid" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="masjid">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.masjid') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.masjid" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.graveyard', 'en')" vid="graveyard" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="graveyard">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.graveyard') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.graveyard" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.labor_line', 'en')" vid="labor_line" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="labor_line">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.labor_line') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.labor_line" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.factory', 'en')" vid="factory" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="factory">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.factory') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.factory" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.school', 'en')" vid="school" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="school">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.school') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.school" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sect_road', 'en')" vid="sect_road" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="sect_road">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sect_road') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.sect_road" @input="subTotalBB" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_bb" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_bb">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_two.sub_total_bb" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.leased_date', 'en')" vid="leased_date" rules="required" v-slot="{ errors }">
                                                                <b-form-group class="row mb-2" label-for="leased_date">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.leased_date') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <date-picker
                                                                    class="form-control"
                                                                    id="leased_date"
                                                                    v-model="tab_two.leased_date"
                                                                    :placeholder="$t('globalTrans.select_date')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :config="{ static: true }"
                                                                >
                                                                </date-picker>
                                                                <div class="invalid-feedback d-block">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.tax_date', 'en')" vid="tax_date" rules="required" v-slot="{ errors }">
                                                                <b-form-group class="row mb-2" label-for="tax_date">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.tax_date') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <date-picker
                                                                    class="form-control"
                                                                    id="tax_date"
                                                                    v-model="tab_two.tax_date"
                                                                    :placeholder="$t('globalTrans.select_date')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :config="{ static: true }"
                                                                >
                                                                </date-picker>
                                                                <div class="invalid-feedback d-block">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneTabTwoStoreApi } from '../../api/routes'

export default {
    props: ['app_id', 'tab_two'],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false
        }
    },
    created () {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        subTotalB () {
            const item1 = parseFloat(this.tab_two.rubber) ? parseFloat(this.tab_two.rubber) : 0
            const item2 = parseFloat(this.tab_two.bamboo) ? parseFloat(this.tab_two.bamboo) : 0
            const item3 = parseFloat(this.tab_two.planted_forest) ? parseFloat(this.tab_two.planted_forest) : 0
            const item4 = parseFloat(this.tab_two.natural_forest) ? parseFloat(this.tab_two.natural_forest) : 0
            const item5 = parseFloat(this.tab_two.sungrass) ? parseFloat(this.tab_two.sungrass) : 0
            const item6 = parseFloat(this.tab_two.paddy_land) ? parseFloat(this.tab_two.paddy_land) : 0
            const item7 = parseFloat(this.tab_two.jackfruit) ? parseFloat(this.tab_two.jackfruit) : 0
            const item8 = parseFloat(this.tab_two.lemon) ? parseFloat(this.tab_two.lemon) : 0
            const item9 = parseFloat(this.tab_two.other_crop) ? parseFloat(this.tab_two.other_crop) : 0
            this.tab_two.sub_total_b = item1 + item2 + item3 + item4 + item5 + item6 + item7 + item8 + item9
        },
        subTotalBB () {
            const item1 = parseFloat(this.tab_two.fallow_land) ? parseFloat(this.tab_two.fallow_land) : 0
            const item2 = parseFloat(this.tab_two.stream) ? parseFloat(this.tab_two.stream) : 0
            const item3 = parseFloat(this.tab_two.masjid) ? parseFloat(this.tab_two.masjid) : 0
            const item4 = parseFloat(this.tab_two.graveyard) ? parseFloat(this.tab_two.graveyard) : 0
            const item5 = parseFloat(this.tab_two.labor_line) ? parseFloat(this.tab_two.labor_line) : 0
            const item6 = parseFloat(this.tab_two.factory) ? parseFloat(this.tab_two.factory) : 0
            const item7 = parseFloat(this.tab_two.school) ? parseFloat(this.tab_two.school) : 0
            const item8 = parseFloat(this.tab_two.sect_road) ? parseFloat(this.tab_two.sect_road) : 0
            this.tab_two.sub_total_bb = item1 + item2 + item3 + item4 + item5 + item6 + item7 + item8
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                this.tab_two.app_id = this.app_id

                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnOneTabTwoStoreApi, this.tab_two)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
