<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '3a) ' : '৩ক) ') + $t('teaGarden.plantation_added')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.extension', 'en')" vid="extension" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="extension">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.extension') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.extension" @input="subTotalC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.replanting', 'en')" vid="replanting" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="replanting">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.replanting') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.replanting" @input="subTotalC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_c" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_c">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.sub_total_c" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '3b) ' : '৩খ) ') + $t('teaGarden.plantation_decreased')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.abandoned_area', 'en')" vid="abandoned_area" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="abandoned_area">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.abandoned_area') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.abandoned_area" @input="subTotalCC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.uprooted_area', 'en')" vid="uprooted_area" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="uprooted_area">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.uprooted_area') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.uprooted_area" @input="subTotalCC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_cc" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_cc">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.sub_total_cc" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '3c) ' : '৩গ) ') + $t('teaGarden.scope_extension')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.ava_area', 'en')" vid="ava_area" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="ava_area">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.ava_area') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.ava_area" @input="subTotalCCC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.area_no', 'en')" vid="area_no" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="area_no">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.area_no') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.area_no" @input="subTotalCCC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.extension_scope', 'en')" vid="extension_scope" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="extension_scope">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.extension_scope') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.extension_scope" @input="subTotalCCC" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_ccc" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_ccc">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_three.sub_total_ccc" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneTabThreeStoreApi } from '../../api/routes'

export default {
    props: ['app_id', 'tab_three'],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false
        }
    },
    created () {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        subTotalC () {
            const item1 = parseFloat(this.tab_three.extension) ? parseFloat(this.tab_three.extension) : 0
            const item2 = parseFloat(this.tab_three.replanting) ? parseFloat(this.tab_three.replanting) : 0
            this.tab_three.sub_total_c = item1 + item2
        },
        subTotalCC () {
            const item1 = parseFloat(this.tab_three.abandoned_area) ? parseFloat(this.tab_three.abandoned_area) : 0
            const item2 = parseFloat(this.tab_three.uprooted_area) ? parseFloat(this.tab_three.uprooted_area) : 0
            this.tab_three.sub_total_cc = item1 + item2
        },
        subTotalCCC () {
            const item1 = parseFloat(this.tab_three.ava_area) ? parseFloat(this.tab_three.ava_area) : 0
            const item2 = parseFloat(this.tab_three.area_no) ? parseFloat(this.tab_three.area_no) : 0
            const item3 = parseFloat(this.tab_three.extension_scope) ? parseFloat(this.tab_three.extension_scope) : 0
            this.tab_three.sub_total_ccc = item1 + item2 + item3
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                this.tab_three.app_id = this.app_id

                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnOneTabThreeStoreApi, this.tab_three)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
