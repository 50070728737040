<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '4a) ' : '৪ক) ') + $t('teaGarden.tea_seeds')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.produced', 'en')" vid="produced" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="produced">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.produced') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.produced" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.used', 'en')" vid="used" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="used">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.used') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.used" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.surplus', 'en')" vid="surplus" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="surplus">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.surplus') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.surplus" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.disposal_surplus')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sold', 'en')" vid="sold" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="sold">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sold') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.sold" @input="subTotalD" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.disposed_otherwise', 'en')" vid="disposed_otherwise" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="disposed_otherwise">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.disposed_otherwise') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.disposed_otherwise" @input="subTotalD" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_d" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_d">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.sub_total_d" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.deficit_met')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.purchased', 'en')" vid="purchased" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="purchased">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.purchased') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.purchased" @input="subTotalDD" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.conn_otherwise', 'en')" vid="conn_otherwise" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="conn_otherwise">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.conn_otherwise') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.conn_otherwise" @input="subTotalDD" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_dd" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_dd">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.sub_total_dd" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.estim_requirement', 'en')" vid="estim_requirement" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="estim_requirement">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.estim_requirement') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.estim_requirement" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="($i18n.locale == 'en' ? '4b) ' : '৪খ) ') + $t('teaGarden.tea_plants')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.area_season', 'en')" vid="area_season" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="area_season">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.area_season') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.area_season" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.plants_infilled', 'en')" vid="plants_infilled" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="plants_infilled">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.plants_infilled') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.plants_infilled" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.avg_plants', 'en')" vid="avg_plants" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="avg_plants">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.avg_plants') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_four.avg_plants" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneTabFourStoreApi } from '../../api/routes'

export default {
    props: ['app_id', 'tab_four'],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false
        }
    },
    created () {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        subTotalD () {
            const item1 = parseFloat(this.tab_four.sold) ? parseFloat(this.tab_four.sold) : 0
            const item2 = parseFloat(this.tab_four.disposed_otherwise) ? parseFloat(this.tab_four.disposed_otherwise) : 0
            this.tab_four.sub_total_d = item1 + item2
        },
        subTotalDD () {
            const item1 = parseFloat(this.tab_four.purchased) ? parseFloat(this.tab_four.purchased) : 0
            const item2 = parseFloat(this.tab_four.conn_otherwise) ? parseFloat(this.tab_four.conn_otherwise) : 0
            this.tab_four.sub_total_dd = item1 + item2
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                this.tab_four.app_id = this.app_id
                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnOneTabFourStoreApi, this.tab_four)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
