<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.tea_waste')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.stock_inhand', 'en')" vid="stock_inhand" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="stock_inhand">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.stock_inhand') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.stock_inhand" @input="subTotalE" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.collection', 'en')" vid="collection" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="collection">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.collection') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.collection" @input="subTotalE" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_e" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_e">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.sub_total_e" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.qty_disposed', 'en')" vid="qty_disposed" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="qty_disposed">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.qty_disposed') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.qty_disposed" @input="subTotalEE" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.qty_destroyed', 'en')" vid="qty_destroyed" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="qty_destroyed">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.qty_destroyed') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.qty_destroyed" @input="subTotalEE" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.sub_total', 'en')" vid="sub_total_ee" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="sub_total_ee">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.sub_total') }}
                                                                    </template>
                                                                    <b-form-input v-model="tab_five.sub_total_ee" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneTabLastStoreApi } from '../../api/routes'

export default {
    props: ['app_id', 'tab_five', 'app_status'],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false
        }
    },
    created () {
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        subTotalE () {
            const item1 = parseFloat(this.tab_five.stock_inhand) ? parseFloat(this.tab_five.stock_inhand) : 0
            const item2 = parseFloat(this.tab_five.collection) ? parseFloat(this.tab_five.collection) : 0
            this.tab_five.sub_total_e = item1 + item2
        },
        subTotalEE () {
            const item1 = parseFloat(this.tab_five.qty_disposed) ? parseFloat(this.tab_five.qty_disposed) : 0
            const item2 = parseFloat(this.tab_five.qty_destroyed) ? parseFloat(this.tab_five.qty_destroyed) : 0
            this.tab_five.sub_total_ee = item1 + item2
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }

                this.tab_five.app_id = this.app_id
                this.tab_five.app_status = this.app_status
                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnOneTabLastStoreApi, this.tab_five)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
