<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_form') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.entry')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="!dataLoaded">
            <b-row>
                <b-col sm="12" v-if="!dataLoaded" style="min-height: 400px"></b-col>
                <b-col sm="12" v-else>
                    <form-wizard class="fair-tab-wrapper" shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
                        <tab-content :title="$t('teaGarden.area_under_tea')" icon="ri-list-settings-line" :before-change="tab1Submit" >
                            <Tab1
                                :app_id="app_id"
                                :tab_main="tab_main"
                                @previous-year-data="getPreviousYearData"
                                ref="tab_1_form"/>
                        </tab-content>
                        <tab-content :title="$t('teaGarden.area_under_other')" icon="ri-list-settings-line" :before-change="tab2Submit">
                            <Tab2 :app_id="app_id" :tab_two="tab_two" ref="tab_2_form"/>
                        </tab-content>
                        <tab-content :title="$t('teaGarden.extension_activities')" icon="ri-list-settings-line" :before-change="tab3Submit">
                            <Tab3 :app_id="app_id" :tab_three="tab_three" ref="tab_3_form"/>
                        </tab-content>
                        <tab-content :title="$t('teaGarden.tea_seeds_and_plants')" icon="ri-list-settings-line" :before-change="tab4Submit">
                            <Tab4 :app_id="app_id" :tab_four="tab_four" ref="tab_4_form"/>
                        </tab-content>

                        <tab-content :title="$t('teaGarden.tea_waste')" icon="ri-list-settings-line" :before-change="tabLastSubmit">
                            <TabLast
                            :app_id="app_id"
                            :tab_five="tab_five"
                            :app_status="app_status"
                            ref="tab_last_form"/>
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary rounded-pill" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-warning ml-1 mr-1 rounded-pill"
                                    title="Create Draft and Save!"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                    {{$t('globalTrans.save_draft')}}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1 rounded-pill"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                >{{ $t('globalTrans.draft_n_next') }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1 rounded-pill"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                    {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>

import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Tab3 from './Tab3'
import Tab4 from './Tab4'
import TabLast from './TabLast'

import BreadCumb from '@/components/BreadCumb.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneDetailsApi } from '../../api/routes'

export default {
  components: {
    BreadCumb, FormWizard, TabContent, WizardButton, Tab1, Tab2, Tab3, Tab4, TabLast
  },
  name: 'Form',
  props: [],
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        errors: [],
        step: 0,
        activeIndex: 0,
        tab_main: {
            year: 0,
            return_type: 0,
            garden_fact_id: 0,
            seed_bari: 0,
            seed_nurse: 0,
            clone_nurse: 0,
            sub_total_a: 0,
            immature_tea: 0,
            bushes_six_ten: 0,
            bushes_eleven_forty: 0,
            bushes_fortyone_sixty: 0,
            bushes_above_sixty: 0,
            sub_total_aa: 0
        },
        tab_two: {
            rubber: 0,
            bamboo: 0,
            planted_forest: 0,
            natural_forest: 0,
            sungrass: 0,
            paddy_land: 0,
            jackfruit: 0,
            lemon: 0,
            other_crop: 0,
            sub_total_b: 0,
            fallow_land: 0,
            stream: 0,
            masjid: 0,
            graveyard: 0,
            labor_line: 0,
            factory: 0,
            school: 0,
            sect_road: 0,
            sub_total_bb: 0,
            leased_date: '',
            tax_date: ''
        },
        tab_three: {
            extension: 0,
            replanting: 0,
            sub_total_c: 0,
            abandoned_area: 0,
            uprooted_area: 0,
            sub_total_cc: 0,
            ava_area: 0,
            area_no: 0,
            extension_scope: 0,
            sub_total_ccc: 0
        },
        tab_four: {
            produced: 0,
            used: 0,
            surplus: 0,
            sold: 0,
            disposed_otherwise: 0,
            sub_total_d: 0,
            purchased: 0,
            conn_otherwise: 0,
            sub_total_dd: 0,
            estim_requirement: 0,
            area_season: 0,
            plants_infilled: 0,
            avg_plants: 0
        },
        tab_five: {
            stock_inhand: 0,
            collection: 0,
            sub_total_e: 0,
            qty_disposed: 0,
            qty_destroyed: 0,
            sub_total_ee: 0
        },
        app_id: 0,
        app_status: 1,
        draft: 0,
        dataLoaded: false
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.query.id) {
        this.app_id = this.$route.query.id
        this.getDetailsData()
    } else {
        this.dataLoaded = true
    }
  },
  computed: {
    loading: function () {
        return this.$store.state.commonObj.loading
    }
  },
  methods: {
    finalSave () {
        this.$swal({
            title: this.$t('globalTrans.final_save_msg'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.tabLastSubmit()
            } else {
                this.app_status = 1
            }
        })
    },
    async getDetailsData () {
        const result = await RestApi.getData(teaGardenServiceBaseUrl, returnOneDetailsApi, { app_id: this.app_id })
        if (result.success) {
            const data = result.data
            if (data) {
                // tab - 1
                this.tab_main = {
                    year: data.year,
                    return_type: data.return_type,
                    garden_fact_id: data.garden_fact_id,
                    seed_bari: data.seed_bari,
                    seed_nurse: data.seed_nurse,
                    clone_nurse: data.clone_nurse,
                    sub_total_a: data.sub_total_a,
                    immature_tea: data.immature_tea,
                    bushes_six_ten: data.bushes_six_ten,
                    bushes_eleven_forty: data.bushes_eleven_forty,
                    bushes_fortyone_sixty: data.bushes_fortyone_sixty,
                    bushes_above_sixty: data.bushes_above_sixty,
                    sub_total_aa: data.sub_total_aa
                }
                this.activeIndex = 1
                // tab - 2
                if (data.tab_two) {
                    this.tab_two = data.tab_two
                    this.activeIndex = 2
                }
                // tab - 3
                if (data.tab_three) {
                    this.tab_three = data.tab_three
                    this.activeIndex = 3
                }
                // tab - 4
                if (data.tab_four) {
                    this.tab_four = data.tab_four
                    this.activeIndex = 4
                }
                // tab - 5
                if (data.tab_five) {
                    this.tab_five = data.tab_five
                }
            }
            this.dataLoaded = true
        } else {
            this.dataLoaded = false
        }
    },
    async getPreviousYearData () {
        this.$refs.tab_1_form.customLoading(true)
        const searchObj = {
            year: this.tab_main.year,
            return_type: this.tab_main.return_type,
            garden_fact_id: this.tab_main.garden_fact_id
        }
        const result = await RestApi.getData(teaGardenServiceBaseUrl, returnOneDetailsApi, searchObj)
        if (result.success) {
            const prevData = result.data
            if (prevData) {
                this.tab_main = {
                    year: searchObj.year,
                    return_type: searchObj.return_type,
                    garden_fact_id: searchObj.garden_fact_id,
                    seed_bari: prevData.seed_bari,
                    seed_nurse: prevData.seed_nurse,
                    clone_nurse: prevData.clone_nurse,
                    sub_total_a: prevData.sub_total_a,
                    immature_tea: prevData.immature_tea,
                    bushes_six_ten: prevData.bushes_six_ten,
                    bushes_eleven_forty: prevData.bushes_eleven_forty,
                    bushes_fortyone_sixty: prevData.bushes_fortyone_sixty,
                    bushes_above_sixty: prevData.bushes_above_sixty,
                    sub_total_aa: prevData.sub_total_aa
                }
                // tab - 2
                if (prevData.tab_two) {
                    this.tab_two = prevData.tab_two
                }
                // tab - 3
                if (prevData.tab_three) {
                    this.tab_three = prevData.tab_three
                }
                // tab - 4
                if (prevData.tab_four) {
                    this.tab_four = prevData.tab_four
                }
                // tab - 5
                if (prevData.tab_five) {
                    this.tab_five = prevData.tab_five
                }
                this.$refs.tab_1_form.customLoading(false)
            } else {
                this.resetForm(searchObj)
                this.$refs.tab_1_form.customLoading(false)
            }
        } else {
            this.$refs.tab_1_form.customLoading(false)
        }
    },
    resetForm (searchObj) {
        this.tab_main = {
            year: searchObj.year,
            return_type: searchObj.return_type,
            garden_fact_id: searchObj.garden_fact_id,
            seed_bari: 0,
            seed_nurse: 0,
            clone_nurse: 0,
            sub_total_a: 0,
            immature_tea: 0,
            bushes_six_ten: 0,
            bushes_eleven_forty: 0,
            bushes_fortyone_sixty: 0,
            bushes_above_sixty: 0,
            sub_total_aa: 0
        }
        this.tab_two = {
            rubber: 0,
            bamboo: 0,
            planted_forest: 0,
            natural_forest: 0,
            sungrass: 0,
            paddy_land: 0,
            jackfruit: 0,
            lemon: 0,
            other_crop: 0,
            sub_total_b: 0,
            fallow_land: 0,
            stream: 0,
            masjid: 0,
            graveyard: 0,
            labor_line: 0,
            factory: 0,
            school: 0,
            sect_road: 0,
            sub_total_bb: 0,
            leased_date: '',
            tax_date: ''
        }
        this.tab_three = {
            extension: 0,
            replanting: 0,
            sub_total_c: 0,
            abandoned_area: 0,
            uprooted_area: 0,
            sub_total_cc: 0,
            ava_area: 0,
            area_no: 0,
            extension_scope: 0,
            sub_total_ccc: 0
        }
        this.tab_four = {
            produced: 0,
            used: 0,
            surplus: 0,
            sold: 0,
            disposed_otherwise: 0,
            sub_total_d: 0,
            purchased: 0,
            conn_otherwise: 0,
            sub_total_dd: 0,
            estim_requirement: 0,
            area_season: 0,
            plants_infilled: 0,
            avg_plants: 0
        }
        this.tab_five = {
            stock_inhand: 0,
            collection: 0,
            sub_total_e: 0,
            qty_disposed: 0,
            qty_destroyed: 0,
            sub_total_ee: 0
        }
    },
    async tabLastSubmit () {
        const result = await this.$refs.tab_last_form.submit()
        if (result.success) {
            this.$router.push({ path: 'return-one' })
        }
    },
    async tab4Submit () {
        // return true
        const result = await this.$refs.tab_4_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'return-one' })
            } else {
                return true
            }
        }
    },
    async tab3Submit () {
        // return true
        const result = await this.$refs.tab_3_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'return-one' })
            } else {
                return true
            }
        }
    },
    async tab2Submit () {
        // return true
        const result = await this.$refs.tab_2_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'return-one' })
            } else {
                return true
            }
        }
    },
    async tab1Submit () {
        // return true
        const result = await this.$refs.tab_1_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'return-one' })
            } else {
                this.app_id = result.data.id
                return true
            }
        }
    }
  }
}
</script>
